/*
 * @file: index.js
 * @description: App Configration
 * @date: 3 April 2022
 * @author: Anil
 * */
import { useEffect, useState } from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { Redirect } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import configureStore from './config';
import { createBrowserHistory } from 'history';
import { Auth } from './methods/auth';
import Dashboard from './pages/Dashboard';
import AddEditCards from './components/Cards/AddEdit';
import Login from './pages/Login';
import Cards from './components/Cards';
import Forgotpassword from './pages/Forgotpassword';
import Resetpassword from './pages/Resetpassword';
import Profile from './pages/Profile'
import Roleslist from './pages/Roleslist'
import Details from './pages/Reports/Details';
import Settings from './pages/Settings';
import ContentManagement from './pages/ContentManagement';
import './scss/main.scss';
import Payment from './pages/Payment';
import "react-pagination-js/dist/styles.css"; // import css
import CommonDelete from './components/CommonDeleteActiveModal/CommonDelete';
import ContactDetails from './pages/Settings/ContactDetails';
import DashboardPage from './pages/Dashboard/DashboardPage';
import Users from './pages/Users';
import AddEditUser from './pages/Users/AddEditUser';
import UserDetail from './pages/Users/Profiledetail';
// import Categories from './pages/Categories';
// import AddEdit from './pages/Categories/AddEdit';
// import Detail from './pages/Categories/Detail';
import AddEditReseller from './pages/ResellerCategories/AddEdit';
import Employees from './pages/Employees';
import Roles from './pages/Roles';
import AddEditRole from './pages/Roles/AddEdit';
import AddEditContinent from './pages/Continents/AddEditContinent';
import Continent from './pages/Continents/index'
import Countriess from './pages/Countries';
import AddEditCountry from './pages/Countries/AddEditCountry';
import CountryDetail from './pages/Countries/Detail';
import Cities from './pages/CityPages';
import AddEditCity from './pages/CityPages/AddEditCity';
import CityDetail from './pages/CityPages/Detail';
import Plans from './pages/Plans';
import AddEditPlan from './pages/Plans/AddEdit';
import Features from './pages/Features';
import AddEditFeature from './pages/Features/AddEdit';
import Currency from './pages/CurrencyPages';
import AddEditCurrency from './pages/CurrencyPages/AddEditCurrency';
import CurrencyDetail from './pages/CurrencyPages/Detail';
import Coupons from './pages/Coupons';
import AddEditCoupon from './pages/Coupons/AddEdit';
import EmailTemplate from './pages/EmailTemplate';
import AddEditEmailTemplate from './pages/EmailTemplate/AddEdit';
import Regions from './pages/Regions';
import AddEditRegion from './pages/Regions/AddEdit';
import BookingSystem from './pages/BookingSystem';
import AddEditBookingSystem from './pages/BookingSystem/AddEdit';
import Types from './pages/CategoriesType';
import AddEditTypes from './pages/CategoriesType/AddEdit';
import ApplyCurrency from './pages/CurrencyPages/Applycurrencys';
import POS from './pages/POS';
import AddEditPOS from './pages/POS/AddEdit';
import Orders from './pages/POS/Orders/Orders';
import Holiday from './pages/Holiday';
import AddEditPrice from './pages/DynamicPrice/AddEdit';
import DynamicPrice from './pages/DynamicPrice';
import AddEditEarlyBirdPricing from './pages/DynamicPrice/Early/AddEdit';
import AddEditEarlyBirdTimeFrames from './pages/DynamicPrice/TimeFrames/AddEdit';
import AddEditEarlyBirdInventory from './pages/DynamicPrice/Inventory/AddEdit';
// import AddEditProduct from './pages/Categories/Product/AddEdit';
// import product from './pages/Categories/Product/Product';
import AddEditLastMinuteFixedDates from './pages/DynamicPrice/LastMinuteFixedDates/AddEdit';
import AddEditLastMinuteInventory from './pages/DynamicPrice/LastMinuteInventory/AddEdit';
import Customer from "./pages/Customers";
import ViewCustomer from "./pages/Customers/Profiledetail";
import Reseller from './pages/ResellerPages';
import CRM from "./pages/CRM";
import AddEditCRM from "./pages/CRM/AddEdit";
import AddEditSkills from './pages/SkillsPages/AddEditSkills';
import Skills from './pages/SkillsPages';
import Industry from './pages/Industry';
import AddEditIndustry from './pages/Industry/AddEditIndustry';
import { ToastContainer } from 'react-toastify';
import AddEditSubSkills from './pages/SubSkillsPages/AddEditSubSkills';
import SubSkills from './pages/SubSkillsPages';
import Assessments from './pages/Assessment';
import QuestionsLists from './pages/Questions';
;
import AddEditquestion from './pages/Questions/AddEditQuestion';
// import AddEditAssessments from './pages/Assessment/AddEditAssessment ';
import Blogs from './pages/Blogs';
import AddEditBlog from './pages/Blogs/AddEditBlogs';
import Faq from './pages/Faq';
import AddEditFaq from './pages/Faq/AddEditFaq';
import AddEditcategory from './pages/category/AddEditcategory';
import CategoryList from './pages/category';
import Categorydetail from './pages/category/categorydetail';
import BlogDetail from './pages/Blogs/Detail';
import FaqDetail from './pages/Faq/Detail';
import Projects from './pages/ProjectManagement';
import Technology from './pages/TechnoLogyPages';
import AddEdittechnology from './pages/TechnoLogyPages/AddEditTechnology';
import Speciality from './pages/Speciality';
import AddEditSpeciality from './pages/Speciality/AddEditSpeciality';
import SpecialityDetail from './pages/Speciality/Detail';
import AddEditAssessments from './pages/Assessment/AddEditAssessment';
import ComissionSetting from './pages/SettingsComission/inde';
import RecruiterListing from './pages/ReferalModuleRecruiter';
import Reqrooter from './pages/Reqrooter';
import Chat from './components/global/Chat/Chat';
import AdminReport from './pages/Reports/AdminReport';
import EmployerReport from './pages/Reports/EployerReport';
import VirtualReport from './pages/Reports/VirtualReport';
import Testonimal from './pages/Testonimal';
import AddEditTestonomal from './pages/Testonimal/AddEditTestonomal';
import DetailTestonomal from './pages/Testonimal/DetailTestonomal';
import Contactus from './pages/ContactListing/Contactus';
import ContactDetail from './pages/ContactListing/ContactDetail';
import RecruiterReport from './pages/Reports/RecruiterReport';
import Transactions from './pages/Transactions/Transactions';
import Setpassword from './pages/SetPassword/Setpassword';
import Vapayment from './pages/VaAdminPayment/Vapayment';
import Addbankdetail from './pages/VaAdminPayment/Addbankdetail';
import Createcontract from './pages/Createcontract/CreateContact';
import Contracts from './pages/Createcontract/Contracts';
import Virtualpayment from './pages/Payments/Virtualpayment';
import ContractDetail from './pages/Createcontract/Contractdetail';
import EmployerContract from './pages/EmployerContract/EmployerContract';
import EmpContractDetail from './pages/EmployerContract/EmpContractDetail';
import EditEmpContract from './pages/EmployerContract/EditEmpContract';
import BillingDetals from './pages/Createcontract/ViewBillingDetail';
import AddEditHardSkills from './pages/SubSkillsPages/AddEditSubSkills/EditHardSkills';





export const history = createBrowserHistory();
/************ store configration *********/
const { persistor, store } = configureStore(history);

export default () => {
  const [CloseChat, setCloseChat] = useState(new Date());
  const user = useSelector(state => state.user);

  return (<>

    <PersistGate loading={'loading ...'} persistor={persistor}>
      <ConnectedRouter history={history}>
        {/* <CommonDelete show="none" /> */}
        <Router>
          <Switch>
            <Route exact={true} path="/dashboard" store={store} requireAuth={Auth} component={Dashboard} />
            <Route exact={true} path="/employees" store={store} requireAuth={Auth} component={Employees} />
            <Route exact={true} path="/dashboardpage" store={store} requireAuth={Auth} component={DashboardPage} />
            <Route exact={true} path="/settings/appointment/contact-details" store={store} requireAuth={Auth} component={ContactDetails} />
            <Route exact={true} path="/rolelist" store={store} requireAuth={Auth} component={Roleslist} />
            <Route exact={true} path="/pos" store={store} requireAuth={Auth} component={POS} />
            <Route exact={true} path="/pos/orders" store={store} requireAuth={Auth} component={Orders} />
            <Route exact={true} path="/pos/add" store={store} requireAuth={Auth} component={AddEditPOS} />
            <Route exact={true} path="/pos/edit/:id" store={store} requireAuth={Auth} component={AddEditPOS} />
            <Route exact={true} path="/users" store={store} requireAuth={Auth} component={Users} />
            <Route exact={true} path="/customer" store={store} requireAuth={Auth} component={Customer} />
            <Route exact={true} path="/customer/add" store={store} requireAuth={Auth} component={AddEditUser} />
            <Route exact={true} path="/customer/edit/:id" store={store} requireAuth={Auth} component={AddEditUser} />
            <Route exact={true} path="/customer/view/:id" store={store} requireAuth={Auth} component={ViewCustomer} />

            <Route exact={true} path="/industry" store={store} requireAuth={Auth} component={Industry} />
            <Route exact={true} path="/industry/add" store={store} requireAuth={Auth} component={AddEditIndustry} />
            <Route exact={true} path="/industry/:role" store={store} requireAuth={Auth} component={Industry} />
            <Route exact={true} path="/industry/edit/:id" store={store} requireAuth={Auth} component={AddEditIndustry} />
            <Route exact={true} path="/industry/:role/add" store={store} requireAuth={Auth} component={AddEditIndustry} />
            <Route exact={true} path="/industry/:role/edit/:id" store={store} requireAuth={Auth} component={AddEditIndustry} />
            {/* <Route exact={true} path="/industryDetail/:id" store={store} requireAuth={Auth} component={IndustryDetail} /> */}

            {/*  For Cards */}
            <Route exact={true} path='/savedcard' requireAuth={Auth} store={store} component={Cards} />
            <Route exact={true} path='/card/add' requireAuth={Auth} store={store} component={AddEditCards} />
            <Route exact={true} path='/card/edit/:id' requireAuth={Auth} store={store} component={AddEditCards} />




            <Route exact={true} path="/payment" store={store} requireAuth={Auth} component={Payment} />

            <Route exact={true} path="/roles" store={store} requireAuth={Auth} component={Roles} />
            <Route exact={true} path="/roles/add" store={store} requireAuth={Auth} component={AddEditRole} />
            <Route exact={true} path="/roles/edit/:id" store={store} requireAuth={Auth} component={AddEditRole} />
            <Route exact={true} path="/users/add" store={store} requireAuth={Auth} component={AddEditUser} />
            <Route exact={true} path="/users/:role" store={store} requireAuth={Auth} component={Users} />
            <Route exact={true} path="/users/edit/:id" store={store} requireAuth={Auth} component={AddEditUser} />
            <Route exact={true} path="/users/:role/add" store={store} requireAuth={Auth} component={AddEditUser} />
            <Route exact={true} path="/users/:role/edit/:id" store={store} requireAuth={Auth} component={AddEditUser} />
            <Route exact={true} path="/userDetail/:id" store={store} requireAuth={Auth} component={UserDetail} />
            {/* <Route exact={true} path="/categories" store={store} requireAuth={Auth} component={Categories} />
                            <Route exact={true} path="/categories/add" store={store} requireAuth={Auth} component={AddEditCategory} />
                            <Route exact={true} path="/categories/edit/:id" store={store} requireAuth={Auth} component={AddEditCategory} />
                            <Route exact={true} path="/category/:type" store={store} requireAuth={Auth} component={Categories} />
                            <Route exact={true} path="/category/:type/add" store={store} requireAuth={Auth} component={AddEditCategory} />
                            <Route exact={true} path="/category/product/:type" store={store} requireAuth={Auth} component={productCategory} />
                            <Route exact={true} path="/category/product/:type/add" store={store} requireAuth={Auth} component={AddEditProductCategory} />
                            <Route exact={true} path="/category/product/:type/edit/:id" store={store} requireAuth={Auth} component={AddEditProductCategory} />
                            <Route exact={true} path="/category/:type/edit/:id" store={store} requireAuth={Auth} component={AddEditCategory} /> */}
            <Route exact={true} path="/content" store={store} requireAuth={Auth} component={ContentManagement} />
            <Route exact={true} path="/profile" store={store} requireAuth={Auth} component={Profile} />
            <Route exact={true} path="/profile/:tab" store={store} requireAuth={Auth} component={Settings} />
            <Route exact={true} path="/login" store={store} requireAuth={Auth} component={Login} />
            <Route exact={true} path="/forgotpassword" store={store} requireAuth={Auth} component={Forgotpassword} />
            <Route exact={true} path="/resetpassword" store={store} requireAuth={Auth} component={Resetpassword} />
            {/* Categories Type */}
            <Route exact={true} path="/types" store={store} requireAuth={Auth} component={Types} />
            <Route exact={true} path="/types/add" store={store} requireAuth={Auth} component={AddEditTypes} />
            <Route exact={true} path="/types/edit/:id" store={store} requireAuth={Auth} component={AddEditTypes} />
            {/* Feature */}
            <Route exact={true} path="/features" store={store} requireAuth={Auth} component={Features} />
            <Route exact={true} path="/features/add" store={store} requireAuth={Auth} component={AddEditFeature} />
            <Route exact={true} path="/features/edit/:id" store={store} requireAuth={Auth} component={AddEditFeature} />
            {/* Continents */}
            <Route exact={true} path="/continents" store={store} requireAuth={Auth} component={Continent} />
            <Route exact={true} path="/continents/add" store={store} requireAuth={Auth} component={AddEditContinent} />
            <Route exact={true} path="/continents/edit/:id" store={store} requireAuth={Auth} component={AddEditContinent} />
            {/* Plans */}
            <Route exact={true} path="/plans" store={store} requireAuth={Auth} component={Plans} />
            <Route exact={true} path="/plans/add" store={store} requireAuth={Auth} component={AddEditPlan} />
            <Route exact={true} path="/plans/edit/:id/:copy" store={store} requireAuth={Auth} component={AddEditPlan} />
            {/* Booking System */}
            <Route exact={true} path="/bookingSystem" store={store} requireAuth={Auth} component={BookingSystem} />
            <Route exact={true} path="/bookingSystem/add" store={store} requireAuth={Auth} component={AddEditBookingSystem} />
            <Route exact={true} path="/bookingSystem/edit/:id" store={store} requireAuth={Auth} component={AddEditBookingSystem} />
            {/* Coupon */}
            <Route exact={true} path="/coupon" store={store} requireAuth={Auth} component={Coupons} />
            <Route exact={true} path="/coupon/add" store={store} requireAuth={Auth} component={AddEditCoupon} />
            <Route exact={true} path="/coupon/edit/:id" store={store} requireAuth={Auth} component={AddEditCoupon} />
            {/* Country */}
            <Route exact={true} path="/countries" store={store} requireAuth={Auth} component={Countriess} />
            <Route exact={true} path="/countries/add" store={store} requireAuth={Auth} component={AddEditCountry} />
            <Route exact={true} path="/countries/edit/:id" store={store} requireAuth={Auth} component={AddEditCountry} />
            <Route exact={true} path="/countries/:id" store={store} requireAuth={Auth} component={CountryDetail} />
            {/* Region */}
            <Route exact={true} path="/regions" store={store} requireAuth={Auth} component={Regions} />
            <Route exact={true} path="/regions/add" store={store} requireAuth={Auth} component={AddEditRegion} />
            <Route exact={true} path="/regions/edit/:id" store={store} requireAuth={Auth} component={AddEditRegion} />
            {/* City */}
            <Route exact={true} path="/cities" store={store} requireAuth={Auth} component={Cities} />
            <Route exact={true} path="/cities/add" store={store} requireAuth={Auth} component={AddEditCity} />
            <Route exact={true} path="/cities/edit/:id" store={store} requireAuth={Auth} component={AddEditCity} />
            <Route exact={true} path="/cities/:id" store={store} requireAuth={Auth} component={CityDetail} />
            {/* Currency */}
            <Route exact={true} path="/currency" store={store} requireAuth={Auth} component={Currency} />
            <Route exact={true} path="/currency/add" store={store} requireAuth={Auth} component={AddEditCurrency} />
            <Route exact={true} path="/currency/edit/:id" store={store} requireAuth={Auth} component={AddEditCurrency} />
            <Route exact={true} path="/currency/:id" store={store} requireAuth={Auth} component={CurrencyDetail} />
            {/* Apply Currency */}
            <Route exact={true} path="/applycurrency" store={store} requireAuth={Auth} component={ApplyCurrency} />
            {/* Holiday */}
            <Route exact={true} path="/holiday" store={store} requireAuth={Auth} component={Holiday} />
            {/* Last Minute Pricing */}
            <Route exact={true} path="/dynamicprice/lastminutefixeddates/add" requireAuth={Auth} component={AddEditLastMinuteFixedDates} />
            <Route exact={true} path="/dynamicprice/lastminutefixeddates/edit/:id/:copy" requireAuth={Auth} component={AddEditLastMinuteFixedDates} />
            <Route exact={true} path="/dynamicprice/lastminuteinventory/add" requireAuth={Auth} component={AddEditLastMinuteInventory} />
            <Route exact={true} path="/dynamicprice/lastminuteinventory/edit/:id/:copy" requireAuth={Auth} component={AddEditLastMinuteInventory} />
            {/* Early Bird Pricing */}
            <Route exact={true} path="/dynamicprice/earlybirdcustomdates/add" requireAuth={Auth} component={AddEditEarlyBirdPricing} />
            <Route exact={true} path="/dynamicprice/earlybirdcustomdates/edit/:id/:copy" requireAuth={Auth} component={AddEditEarlyBirdPricing} />
            <Route exact={true} path="/dynamicprice/earlybirdtimeframes/add" requireAuth={Auth} component={AddEditEarlyBirdTimeFrames} />
            <Route exact={true} path="/dynamicprice/earlybirdtimeframes/edit/:id/:copy" requireAuth={Auth} component={AddEditEarlyBirdTimeFrames} />
            <Route exact={true} path="/dynamicprice/earlybirdinventory/add" requireAuth={Auth} component={AddEditEarlyBirdInventory} />
            <Route exact={true} path="/dynamicprice/earlybirdinventory/edit/:id/:copy" requireAuth={Auth} component={AddEditEarlyBirdInventory} />
            {/* Dynamic Price */}
            <Route exact={true} path="/dynamicpricelist" store={store} requireAuth={Auth} component={DynamicPrice} />
            <Route exact={true} path="/dynamicprice/add" store={store} requireAuth={Auth} component={AddEditPrice} />
            <Route exact={true} path="/dynamicprice/:type" store={store} requireAuth={Auth} component={DynamicPrice} />
            <Route exact={true} path="/dynamicprice/:type/add" store={store} requireAuth={Auth} component={AddEditPrice} />
            <Route exact={true} path="/dynamicprice/:type/edit/:id/:copy" store={store} requireAuth={Auth} component={AddEditPrice} />

            <Route exact={true} path="/crm" store={store} requireAuth={Auth} component={CRM} />
            <Route exact={true} path="/crm/add" store={store} requireAuth={Auth} component={AddEditCRM} />
            <Route exact={true} path="/crm/edit/:id" store={store} requireAuth={Auth} component={AddEditCRM} />

            {/* Email Template */}
            <Route exact={true} path="/emailtemplate" store={store} requireAuth={Auth} component={EmailTemplate} />
            <Route exact={true} path="/emailtemplate/add" store={store} requireAuth={Auth} component={AddEditEmailTemplate} />
            <Route exact={true} path="/emailtemplate/edit/:id" store={store} requireAuth={Auth} component={AddEditEmailTemplate} />

            {/*  For Reseller  */}
            <Route exact={true} path="/reseller" store={store} requireAuth={Auth} component={Reseller} />
            <Route exact={true} path="/reseller/addedit/:id?" store={store} requireAuth={Auth} component={AddEditReseller} />
            {/*  Routes for Skills  */}
            <Route exact={true} path="/addeditskill/:id?" requireAuth={Auth} store={store} component={AddEditSkills} />
            <Route exact={true} path="/skills" requireAuth={Auth} store={store} component={Skills} />
            {/* ###### Routes for Master Skills############## */}
            <Route exact={true} path="/masterskils" requireAuth={Auth} store={store} component={SubSkills} />
            <Route exact={true} path="/addedithardskils/:id?" requireAuth={Auth} store={store} component={AddEditHardSkills} />
            <Route exact={true} path="/addeditmasterskils/:id?" requireAuth={Auth} store={store} component={AddEditSubSkills} />



            <Route exact={true} path="/assessment" store={store} requireAuth={Auth} component={Assessments} />
            <Route exact={true} path="/AddEditAssessment" requireAuth={Auth} store={store} component={AddEditAssessments} />
            <Route exact={true} path="/AddEditAssessment/:id?" requireAuth={Auth} store={store} component={AddEditAssessments} />

            {/* <Route exact={true} path="/category" store={store} requireAuth={Auth} component={Category} />
                            <Route exact={true} path="/AddEditCategory" requireAuth={Auth} store={store} component={AddEditcategory}/>
                            <Route exact={true} path="/AddEditCategory/:id?" requireAuth={Auth} store={store} component={AddEditcategory}/>  */}

            <Route exact={true} path="/questions/:id?" store={store} requireAuth={Auth} component={QuestionsLists} />
            <Route exact={true} path="/AddEditquestions" requireAuth={Auth} store={store} component={AddEditquestion} />
            <Route exact={true} path="/AddEditquestions/:id?" requireAuth={Auth} store={store} component={AddEditquestion} />

            <Route exact={true} path="/blogs" store={store} requireAuth={Auth} component={Blogs} />
            <Route exact={true} path="/AddEditBlogs" requireAuth={Auth} store={store} component={AddEditBlog} />
            <Route exact={true} path="/AddEditBlogs/:id?" requireAuth={Auth} store={store} component={AddEditBlog} />
            <Route exact={true} path="/blogsDetails/:id?" requireAuth={Auth} store={store} component={BlogDetail} />


            <Route exact={true} path="/faq" store={store} requireAuth={Auth} component={Faq} />
            <Route exact={true} path="/AddEditfaq" requireAuth={Auth} store={store} component={AddEditFaq} />
            <Route exact={true} path="/AddEditfaq/:id?" requireAuth={Auth} store={store} component={AddEditFaq} />
            <Route exact={true} path="/faqDetails/:id?" requireAuth={Auth} store={store} component={FaqDetail} />

            <Route exact={true} path="/speciality" store={store} requireAuth={Auth} component={Speciality} />
            <Route exact={true} path="/AddEditspeciality" requireAuth={Auth} store={store} component={AddEditSpeciality} />
            <Route exact={true} path="/AddEditspeciality/:id?" requireAuth={Auth} store={store} component={AddEditSpeciality} />
            <Route exact={true} path="/specialityDetails/:id?" requireAuth={Auth} store={store} component={SpecialityDetail} />

            <Route exact={true} path="/categorylist" store={store} requireAuth={Auth} component={CategoryList} />
            <Route exact={true} path="/AddEditcategory" requireAuth={Auth} store={store} component={AddEditcategory} />
            <Route exact={true} path="/AddEditcategory/:id?" requireAuth={Auth} store={store} component={AddEditcategory} />
            <Route exact={true} path="/categorydetail/:id?" requireAuth={Auth} store={store} component={Categorydetail} />
            {/* Routes for Project Management */}
            <Route exact={true} path="/projects" requireAuth={Auth} store={store} component={Projects} />

            {/* Routes for Technology  */}
            <Route exact={true} path="/technologies" requireAuth={Auth} store={store} component={Technology} />
            <Route exact={true} path="/technology/:id?" requireAuth={Auth} store={store} component={AddEdittechnology} />
            <Route exact={true} path="/comissionsetting" requireAuth={Auth} store={store} component={ComissionSetting} />
            <Route exact={true} path="/refferalls" requireAuth={Auth} store={store} component={RecruiterListing} />
            <Route exact={true} path="/Reqrooter/:id/:role" requireAuth={Auth} store={store} component={Reqrooter} />
            <Route exact={true} path="/report" requireAuth={Auth} store={store} component={AdminReport} />
            <Route exact={true} path="/employer" requireAuth={Auth} store={store} component={EmployerReport} />
            <Route exact={true} path="/virtual" requireAuth={Auth} store={store} component={VirtualReport} />
            <Route exact={true} path="/details" requireAuth={Auth} store={store} component={Details} />
            <Route exact={true} path="/testonimal" requireAuth={Auth} store={store} component={Testonimal} />
            <Route exact={true} path="/testonimal/add" requireAuth={Auth} store={store} component={AddEditTestonomal} />
            <Route exact={true} path="/testonimal/edit/:id?" requireAuth={Auth} store={store} component={AddEditTestonomal} />
            <Route exact={true} path="/testonimal/detail/:id?" requireAuth={Auth} store={store} component={DetailTestonomal} />
            <Route exact={true} path="/contact/list" requireAuth={Auth} store={store} component={Contactus} />
            <Route exact={true} path="/contact/:id?" requireAuth={Auth} store={store} component={ContactDetail} />
            <Route exact={true} path="/recruiter" requireAuth={Auth} store={store} component={RecruiterReport} />
            <Route exact={true} path="/transactions" requireAuth={Auth} store={store} component={Transactions} />
            <Route exact={true} path="/set/password" requireAuth={Auth} store={store} component={Setpassword} />
            <Route exact={true} path="/payment/quote" requireAuth={Auth} store={store} component={Vapayment} />
            <Route exact={true} path="/bank/detail" requireAuth={Auth} store={store} component={Addbankdetail} />
            <Route exact={true} path="/contract" requireAuth={Auth} store={store} component={Contracts} />
            <Route exact={true} path="/create/contract" requireAuth={Auth} store={store} component={Createcontract} />
            <Route exact={true} path="/edit/contract/:id?" requireAuth={Auth} store={store} component={Createcontract} />
            <Route exact={true} path="/detail/contract/:id?" requireAuth={Auth} store={store} component={ContractDetail} />
            <Route exact={true} path="/payment/va" requireAuth={Auth} store={store} component={Virtualpayment} />
            <Route exact={true} path="/emp/contr" requireAuth={Auth} store={store} component={EmployerContract} />
            <Route exact={true} path="/detail/empcontract/:id?" requireAuth={Auth} store={store} component={EmpContractDetail} />
            <Route exact={true} path="/edit/empcontract/:id?" requireAuth={Auth} store={store} component={EditEmpContract} />
            <Route exact={true} path="/billing/detail/:id?" requireAuth={Auth} store={store} component={BillingDetals} />


            transactions    <Route exact path="/">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Router>
      </ConnectedRouter>
    </PersistGate>


    <div className="modal fade " id="chatmodal" tabIndex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Chat</h5>
            <button type="button" onClick={e => setCloseChat(true)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Chat update={CloseChat} />
          </div>
          <div className="modal-footer">

          </div>
        </div>
      </div>
    </div>
    {user.id && user.id != "" && localStorage.getItem('token') ? <div onClick={e => setCloseChat(false)} className="floating-chat-icon" id='openChat' data-toggle="modal" data-target="#chatmodal">
      <span  >🗨️</span>
    </div> : null}
    <div id="loader" className="loaderDiv d-none">
      <div>
        <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
      </div>
    </div>
    <ToastContainer position='top-right' />
  </>
  );
};
